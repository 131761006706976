

html, body{
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0; 
}

body{
  background-image: url(../public/images/background.jpg);
  background-position: center;
  background-size: cover;
}

.juander-logo{
  width: 40px;
  height: 40px;
  background-size: contain;
  background-position: center;
}
